import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

import authRoutes from '@/router/Auth'
import homeRoutes from '@/router/Home'

const ErrorScreen = () => import('_screens/ErrorScreen')

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Root',
        redirect: { name: 'Home' },
    },
    ...authRoutes,
    ...homeRoutes,
    {
        path: '*',
        name: 'error',
        component: ErrorScreen,
    },
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return { x: 0, y: 0, behavior: 'smooth' }
    },
})

const hasPermission = (permissions) => {
    return store.getters['permissions/hasPermission'](permissions)
}

router.beforeEach((to, from, next) => {
    const isAuthorized = Boolean(localStorage.getItem('accessToken'))

    const toAuthScreen = to.matched.some((route) => route.name === 'Auth')
    const toHomeScreen = to.matched.some((route) => route.name === 'Home')

    if (isAuthorized && !toHomeScreen) {
        // Если авторизован и открываем экран не из Home, то редирект на Home
        console.log('r1')

        next({ name: 'Home' })
    } else if (!isAuthorized && !toAuthScreen) {
        // Если НЕ авторизован и открываем экран не из Авторизации, то редирект на авторизацию
        console.log('r2')

        next({ name: 'Auth' })
    } else {
        // Логика по умолчанию
        const permissions = to?.meta?.permissions
        const isAllowed = permissions ? hasPermission(permissions) : true

        if (!isAllowed) {
            console.log('r3')
            next({ name: 'Home' })
        } else {
            console.log('r4')
            next()
        }
    }
})

export default router
