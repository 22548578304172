import getters from './getters'
import { companyTypes } from '_features/companyTypes/constants/companyTypes'

const state = {
    isFetching: false,
    errors: [],
    items: companyTypes,
}

export default {
    namespaced: true,
    state,
    getters,
}
