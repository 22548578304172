export const companyTypes = {
    builder: {
        id: '5c64d1bb-c526-414e-9971-0e95eee4e580',
        name: 'Застройщик',
        code: 'builder',
    },
    service_company: {
        id: 'c0ef3086-1ab3-4186-9d12-80400fbed34c',
        name: 'Управляющая компания',
        code: 'service_company',
    },
    serving_company: {
        id: 'b3653677-80ad-48e9-863a-a525b665f1cb',
        name: 'Обслуживающая компания',
        code: 'serving_company',
    },
}
