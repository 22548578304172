import { PERMISSIONS } from '_features/permissions/constants'

const BuildingsNavigator = () => import('_screens/Home/Buildings/BuildingsNavigator')

const BuildingsScreen = () => import('_screens/Home/Buildings/BuildingsScreen')
const BuildingEditScreen = () => import('_screens/Home/Buildings/BuildingEditScreen')
const BuildingViewScreen = () => import('_screens/Home/Buildings/BuildingViewScreen')

const buildingsRoutes = [
    {
        path: 'buildings',
        component: BuildingsNavigator,
        children: [
            {
                path: '',
                name: 'Buildings',
                component: BuildingsScreen,
                meta: {
                    screenOptions: {
                        title: 'Дома',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_BUILDING],
                },
            },
            {
                path: 'edit/:buildingId',
                name: 'BuildingEdit',
                component: BuildingEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Дом',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_LOCATION_BUILDING],
                },
            },
            {
                path: 'view/:buildingId',
                name: 'BuildingView',
                component: BuildingViewScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Информация о доме',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_BUILDING],
                },
            },
        ],
    },
]

export default buildingsRoutes
