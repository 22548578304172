<template>
    <UIAutocomplete
        v-bind="$attrs"
        v-model="model"
        :apiMethod="method"
        item-text="name"
        item-value="id"
        :label="label"
        :sort-by="['name']"
        :sort-orders="['asc']"
    />
</template>

<script>
    import API from '_api'

    import UIAutocomplete from '_ui/forms/UIAutocomplete.vue'

    export default {
        name: 'FEntrancePicker',

        components: {
            UIAutocomplete,
        },

        props: {
            label: { type: String, default: 'Подъезд' },
            value: { type: [Array, Object] },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            method() {
                return API.services.entrance.getEntrances
            },
        },
    }
</script>
