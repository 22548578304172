import { map as _map } from 'lodash'

import Entity from '_entities/base/Entity'

import ApplicantFeedback from '_entities/applicantFeedback/ApplicantFeedback'
import Approval from '_entities/approval/Approval'
import BuildingLink from '_entities/building/BuildingLink'
import ChatLink from '_entities/chat/ChatLink'
import CsiFeedback from '_entities/csiFeedback/CsiFeedback'
import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'
import PlacementLink from '_entities/placement/PlacementLink'
import PremiseLink from '_entities/premise/PremiseLink'
import ProjectLink from '_entities/project/ProjectLink'
import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketInspectionInfo from '_entities/ticket/TicketInspectionInfo'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketLink from '_entities/ticket/TicketLink'
import TicketSCAcceptanceInfo from '_entities/ticket/TicketSCAcceptanceInfo'
import TicketSource from '_entities/ticket/TicketSource'
import TicketStageLink from '_entities/ticket/TicketStageLink'
import TicketTypeLink from '_entities/ticket/TicketTypeLink'
import RoomLink from '_entities/room/RoomLink'
import EntranceLink from '_entities/entrance/EntranceLink'
import SectorLink from '_entities/sector/SectorLink'
// import Service from '_entities/service/Service'
import UserLink from '_entities/user/UserLink'

class Ticket extends Entity {
    get schema() {
        return {
            additionalReviewers: ['additional_reviewers', [], (value) => value.map((item) => this.getEntityValue(UserLink, item))],
            applicant: ['applicant', undefined, (value) => this.getEntityValue(UserLink, value)],
            applicantChat: ['applicant_chat', undefined, (value) => this.getEntityValue(ChatLink, value)],
            applicantFeedbacks: ['applicant_feedbacks', [], (value) => value.map((item) => this.getEntityValue(ApplicantFeedback, item))],
            approval: ['approval', undefined, (value) => this.getEntityValue(Approval, value)],
            appointer: ['appointer', undefined, (value) => this.getEntityValue(UserLink, value)],
            auditors: ['auditors', [], (value) => value.map((item) => this.getEntityValue(UserLink, item))],
            building: ['building', undefined, (value) => this.getEntityValue(BuildingLink, value)],
            chatEnabled: ['chat_enabled', undefined, (value) => !!value], // Boolean
            chatResponsible: ['chat_responsible', undefined, (value) => this.getEntityValue(UserLink, value)],
            childTickets: ['child_tickets', [], (value) => value.map((item) => this.getEntityValue(TicketLink, item))],
            coordinates: ['coordinates', undefined, (value) => this.getCoordinatesValue(value)], // String
            countOnCheck: ['count_on_check', undefined, (value) => this.getIntegerValue(value)],
            createdAt: ['created_at', undefined, (value) => this.getIntegerValue(value)],
            createdBy: ['created_by', undefined, (value) => this.getEntityValue(UserLink, value)],
            csiFeedback: ['csi_feedback', [], (value) => value.map((item) => this.getEntityValue(CsiFeedback, value))],
            dateAdditionalCheck: ['date_additional_check', undefined, (value) => this.getIntegerValue(value)],
            dateAssignedExecutor: ['date_assigned_executor', undefined, (value) => this.getIntegerValue(value)],
            dateClose: ['date_close', undefined, (value) => this.getIntegerValue(value)],
            dateComplete: ['date_complete', undefined, (value) => this.getIntegerValue(value)],
            dateControl: ['date_control', undefined, (value) => this.getIntegerValue(value)],
            dateFeedback: ['date_feedback', undefined, (value) => this.getIntegerValue(value)],
            dateOnAdditionalCheck: ['date_on_additional_check', undefined, (value) => this.getIntegerValue(value)],
            datePlannedCompletion: ['date_planned_completion', undefined, (value) => this.getIntegerValue(value)],
            dateSpecialistArrival: ['date_specialist_arrival', undefined, (value) => this.getIntegerValue(value)],
            dateTakeInWork: ['date_take_in_work', undefined, (value) => this.getIntegerValue(value)],
            documentId: ['document_id', undefined, (value) => value],
            documentType: ['document_type', undefined, (value) => value],
            executor: ['executor', undefined, (value) => this.getEntityValue(UserLink, value)],
            files: ['files', [], (value) => value.map((item) => this.getEntityValue(Doc, item))],
            floor: ['floor', undefined, (value) => this.getIntegerValue(value)],
            hasChatMessages: ['has_chat_messages', undefined, (value) => !!value], // Boolean
            hasUnansweredChatMessages: ['has_unanswered_chat_messages', undefined, (value) => !!value], // Boolean
            id: ['id', undefined, (value) => value], // UUID
            images: ['images', [], (value) => value.map((item) => this.getEntityValue(Photo, item))],
            imagesDone: ['images_done', [], (value) => value.map((item) => this.getEntityValue(Photo, item))],
            imagesRejected: ['images_rejected', [], (value) => value.map((item) => this.getEntityValue(Photo, item))],
            inspectionInfo: ['inspection_info', [], (value) => this.getEntityValue(TicketInspectionInfo, value)],
            isFromWorker: ['is_from_worker', undefined, (value) => !!value], // Boolean
            isMass: ['is_mass', undefined, (value) => !!value], // Boolean
            loadToBitrix: ['load_to_bitrix', undefined, (value) => !!value], // Boolean
            materials: ['materials', undefined, (value) => value], // string
            number: ['number', undefined, (value) => this.getIntegerValue(value)],
            overdueDays: ['overdue_days', undefined, (value) => this.getIntegerValue(value)],
            parentTicket: ['parent_ticket', undefined, (value) => this.getEntityValue(TicketLink, value)],
            penaltyAmount: ['penalty_amount', undefined, (value) => this.getFloatValue(value)],
            placement: ['placement', undefined, (value) => this.getEntityValue(PlacementLink, value)],
            premise: ['premise', undefined, (value) => this.getEntityValue(PremiseLink, value)],
            project: ['project', undefined, (value) => this.getEntityValue(ProjectLink, value)],
            room: ['room', undefined, (value) => this.getEntityValue(RoomLink, value)],
            responsible: ['responsible', undefined, (value) => this.getEntityValue(UserLink, value)],
            scAcceptanceInfo: ['sc_acceptance_info', undefined, (value) => this.getEntityValue(TicketSCAcceptanceInfo, value)],
            section: ['section', undefined, (value) => this.getEntityValue(EntranceLink, value)],
            sector: ['sector', undefined, (value) => this.getEntityValue(SectorLink, value)],
            service: ['service', undefined, (value) => value],
            text: ['text', undefined, (value) => value], // String
            ticketBitrixLink: ['bitrix_ticket_link', undefined, (value) => value], // string URL
            ticketByRefuser: ['ticket_by_refuser', undefined, (value) => !!value], // Boolean
            ticketDepartment: ['ticket_department', undefined, (value) => this.getEntityValue(TicketDepartmentLink, value)],
            ticketKind: ['ticket_kind', undefined, (value) => this.getEntityValue(TicketKindLink, value)],
            ticketSource: ['ticket_source', undefined, (value) => this.getEntityValue(TicketSource, value)],
            ticketStage: ['ticket_stage', undefined, (value) => this.getEntityValue(TicketStageLink, value)],
            ticketType: ['ticket_type', undefined, (value) => this.getEntityValue(TicketTypeLink, value)],
            title: ['title', undefined, (value) => value], // String
            unreadMessagesCount: ['unread_messages_count', [], (value) => this.getIntegerValue(value)],
        }
    }

    static fieldLabels = {
        additionalReviewers: 'Дополнительные проверяющие',
        applicant: 'Заявитель',
        applicantChat: 'Чат с заявителем',
        appointer: 'Назначающий исполнителей',
        auditors: 'Наблюдатели',
        building: 'Дом',
        chatEnabled: 'Доступность чата',
        chatResponsible: 'Ответственный за чат',
        coordinates: 'Координаты',
        countOnCheck: 'Количество проверок',
        createdAt: 'Дата создания',
        createdBy: 'Создавший заявку',
        dateAdditionalCheck: 'Дата и время проверки дополнительным проверяющим',
        dateAssignedExecutor: 'Дата назначения исполнителя',
        dateClose: 'Дата закрытия',
        dateComplete: 'Дата выполнения',
        dateControl: 'Дата контроля',
        dateFeedback: 'Дата получения оценки',
        dateOnAdditionalCheck: ' Дата и время перехода на доп проверку',
        datePlannedCompletion: 'Дата планируемого выполнения',
        dateSpecialistArrival: 'Дата прихода специалиста',
        dateTakeInWork: 'Дата взятия в работу',
        documentLink: 'Ссылка на документ',
        executor: 'Исполнитель',
        files: 'Документы',
        floor: 'Этаж',
        hasChatMessages: 'Сообщения в чате',
        hasUnansweredChatMessages: 'Есть неотвеченные сообщения',
        id: 'ID',
        images: 'Фотографии',
        imagesDone: 'Фото (выполнено)',
        imagesRejected: 'Фото (отклонено)',
        inspectionInfo: 'Информация об обходе',
        isFromWorker: 'Заявка от сотрудника',
        isMass: 'Массовая',
        materials: 'Материалы',
        number: 'Номер',
        overdueDays: 'Количество дней просрочки',
        parentTicket: 'Родительская заявка',
        penaltyAmount: 'Сумма штрафа подрядчика, руб',
        placement: 'Место',
        premise: 'Помещение',
        project: 'ЖК',
        responsible: 'Ответственный',
        room: 'Комната',
        scAcceptanceInfo: 'Информация о приемке',
        section: 'Подъезд',
        sector: 'Сектор',
        service: 'Сервис',
        csiFeedback: 'Отзыв',
        text: 'Текст',
        ticketBitrixLink: 'Связанная заявка в Bitrix',
        ticketByRefuser: 'Заявка от отказника',
        ticketDepartment: 'Отдел',
        ticketKind: 'Вид',
        ticketSource: 'Источник',
        ticketStage: 'Стадия',
        ticketType: 'Тип',
        title: 'Заголовок',
        unreadMessagesCount: 'Кол-во непрочитанных сообщений',
    }

    toServer() {
        return {
            applicant: this.applicant?.id,
            building: this.building?.id,
            date_control: this.dateControl,
            document_id: this.documentId,
            document_type: this.documentType,
            files: _map(this.files, (file) => file.id),
            floor: this.floor,
            images: _map(this.images, (image) => image.id),
            is_from_worker: this.isFromWorker,
            parent_ticket: this.parentTicket?.id,
            premise: this.premise?.id,
            project: this.project?.id,
            responsible: this.responsible?.id,
            section: this.section?.id,
            sector: this.sector?.id,
            text: this.text,
            ticket_department: this.ticketDepartment?.id,
            ticket_kind: this.ticketKind?.id,
            ticket_type: this.ticketType?.id,
            title: this.title,
        }
    }
}

export default Ticket
